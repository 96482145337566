'use strict';

/**
 * Get completude result
 *
 * @param {angular.IHttpService} $http - http service
 * @returns {object} completude service
 */
function controleCompletudeService($http) {
  let ongoingRequest = null; // Tracks the ongoing request
  let ongoingUrl = null; // Tracks the URL of the current request

  return {
    controlSteps: (aide, steps, contribution) => {
      const href = contribution?.id || aide?.id;
      const url = href + '/completude/' + steps.join(',');

      // Check if there's an ongoing request for the same URL
      if (ongoingRequest && ongoingUrl === url) {
        return ongoingRequest; // Return the existing Promise
      }

      // Create a new request and store it
      ongoingUrl = url;
      ongoingRequest = $http
        .get(url)
        .then(({ data }) => data)
        .finally(() => {
          // Reset when the request completes
          ongoingRequest = null;
          ongoingUrl = null;
        });

      return ongoingRequest;
    },
  };
}

angular.module('aides.services').factory('controleCompletudeService', controleCompletudeService);

controleCompletudeService.$inject = ['$http'];
